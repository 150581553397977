<template>
  <div class="ipxx-start-logout px-5">
    <template v-if="errorCode">
      <h1>
        {{ $t('titles.error') }}
      </h1>
      <b-row class="justify-content-center">
        <div class="ipxx-form-responsive-col mt-3">
          <b-alert
            show
            variant="danger"
            v-html="errorMessage"
          />
        </div>
      </b-row>
    </template>
    <div v-else>
      <h1>
        {{ $t('titles.execute_logout') }}
      </h1>
      <fa-icon
        icon="circle-notch"
        size="6x"
        class="fa-spin"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartLogout',
  props: {
    authProviderId: {
      type: Number,
      default: () => null,
    },
  },
  computed: {
    errorCode() {
      return this.$store.getters.getError;
    },
    errorMessage() {
      if (this.errorCode === this.$config.errors.start_unknown_provider) {
        return this.$t('errors.error_unknown_provider');
      }
      if (this.errorCode === this.$config.errors.start_oidc_process_failed) {
        return this.$t('errors.error_oidc');
      }
      return this.$t('errors.unknown_error');
    },
  },
  mounted() {
    const data = {
      id: this.authProviderId,
    };
    this.$store.dispatch('startLogout', data);
  },
};
</script>
