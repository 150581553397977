<template>
  <div class="mx-4 pt-5">
    <ipxx-start-login
      :auth-provider-id="authProviderId"
      :prompt="prompt"
      :username="username"
    />
  </div>
</template>

<script>
import IpxxStartLogin from '@/components/StartLogin';

export default {
  name: 'StartView',
  components: {
    IpxxStartLogin,
  },
  computed: {
    authProviderId() {
      return parseInt(this.$route.params.authProviderId, 10);
    },
    prompt() {
      return this.$route.params.prompt;
    },
    username() {
      return this.$route.params.username;
    },
  },
};
</script>
