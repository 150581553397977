<template>
  <div class="mx-4 pt-5">
    <ipxx-start-logout :auth-provider-id="authProviderId" />
  </div>
</template>

<script>
import IpxxStartLogout from '@/components/StartLogout';

export default {
  name: 'StartLogoutView',
  components: {
    IpxxStartLogout,
  },
  computed: {
    authProviderId() {
      return parseInt(this.$route.params.authProviderId, 10);
    },
  },
};
</script>
